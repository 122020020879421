import {icons, Plugin} from '@ckeditor/ckeditor5-core';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import '../styles.css';
export default class Imagesui extends Plugin {

    constructor(editor) {
        super(editor);
        this.editor = editor;
        this.getMediaURLCallback = null;
        this.modalInstance = null;
    }

    browseModalInstanceController(imagesInstance, $scope, $uibModal, $modalInstance, editorId) {
        $scope.onModalCloseButtonClick = function() {
            let $uibModalInstance = null;
            if (angular.element(document.body).injector().has('$modal')) {
                $uibModalInstance = angular.element(document.body).injector().get('$modal');
            } else {
                $uibModalInstance = angular.element(document.body).injector().get('$uibModal');
            }
            $modalInstance.close();
        };

        $scope.onMediaClick = function () {
            $modalInstance.close();
        };
    }

    openImagePaletteModal(currentEditor) {
        const $uibModal = angular.element(document.body).injector().get('$uibModal');
        const $scope = angular.element(document.body).scope();
        const $signals = angular.element(document.body).injector().get('$signals');
        $scope.currentEditorId = currentEditor.id;
        $scope.currentAreaId = currentEditor.sourceElement.id;

        this.modalInstance = $uibModal.open({
            templateUrl: '/views/ib/cms/eassessment/modal/eassessmentmediabrowserModalCk5.html',
            scope: $scope,
        });

        this.browseModalInstanceController(this, $scope, $uibModal, this.modalInstance);
    }

    init() {
        const editor = this.editor;
        const $signals = angular.element(document.body).injector().get('$signals');
        let currentEditor = null;

        editor.ui.componentFactory.add( 'imagePalette', () => {
            const button = new ButtonView();

            button.set( {
                label: 'Images',
                withText: false,
                icon: icons.imageUpload,
                class: 'ck-search__reset',
                tooltip: true
            } );

            this.listenTo( button, 'execute', () => {
                currentEditor = editor;
                this.openImagePaletteModal(currentEditor);
            } );

            $signals.on('mediaSelected', (data, currentEditorId) => {
                if (editor.id === currentEditorId) {
                    editor.execute( 'insertImage', { source: data } );
                }
                this.modalInstance && this.modalInstance.close();
            });

            return button;
        } );
    }

    getMediaURL(selectedMedia) {
        return selectedMedia.url;
    }
}
