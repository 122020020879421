import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView, clickOutsideHandler, ContextualBalloon, View, Dialog } from '@ckeditor/ckeditor5-ui';
import FormView from './wirisview';
import '../styles.css';
import getRangeText from "../tooltip/utils";
import wirisIcon from './ckeditor5-formula.svg';
import DoubleClickObserver from "./doubleClickObserver";

export default class Wirisui extends Plugin {
    static get requires() {
        return [ContextualBalloon, Dialog];
    }

    init() {
        const editor = this.editor;

        this._balloon = this.editor.plugins.get(ContextualBalloon);
        this.formView = this._createFormView();

        const view = this.editor.editing.view;
        const viewDocument = view.document;
        view.addObserver(DoubleClickObserver);

        editor.ui.componentFactory.add('wiris', locale => {
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'Wiris Editor',
                tooltip: true,
                withText: false,
                icon: wirisIcon
            });

            buttonView.on('execute', () => {
                this._openDialog(null, buttonView);
            });

            return buttonView;
        });

        this._registerDoubleClickListener(viewDocument);

    }

    _registerDoubleClickListener(viewDocument) {
        this.editor.listenTo(
            viewDocument,
            'dblclick',
            (evt, data) => {
                const domTarget = data.domTarget;
                const parentElement = domTarget.parentElement;
                const sourceAttribute = parentElement.attributes['data-source'];

                const hasWirisClass = Array.from(parentElement.classList).some(className => className.includes('wiris'));
                const isSVGSource = sourceAttribute && window.atob(sourceAttribute.value).includes('<math');

                if (hasWirisClass || isSVGSource) {
                    if (sourceAttribute) {
                        const decodedSource = window.atob(sourceAttribute.value);
                        console.log('DB CLICK ==>', decodedSource);
                        this._openDialog(null, decodedSource);
                    }
                }
            },
            { context: 'span' }
        );
    }

    _openDialog(buttonView, initialData = null) {
        const editor = this.editor;
        const dialog = this.editor.plugins.get('Dialog');

        var random_id = angular.createUUID();
        var wirisEditorInstance = null;
        var isCMS = window.location.href.indexOf("index-cms") > -1 || window.location.href.indexOf("index-pdf") > -1 || window.location.href.indexOf("exam-export-pdf") > -1;

        window.signals.on('iFrameRegisterObject', function (data) {
            if (data.uuid === random_id) {
                wirisEditorInstance = data.object;
                if (initialData) {
                    wirisEditorInstance.setMathML(initialData);
                }
            }
        });

        var widgetData;
        var setInitialData = function (data) {
            if (data.uuid === random_id && widgetData.data.mathml) {
                wirisEditorInstance.setMathML(widgetData.data.mathml);
            }
        };

        function isOnline() {
            if (!isCMS) {
                return false;
            }
            return window.location.protocol == 'https:' || window.location.protocol == 'http:';
        }

        // If the button is turned on, hide the dialog.
        if (buttonView && buttonView.isOn) {
            dialog.hide();
            buttonView.isOn = false;
            return;
        }

        if (buttonView) {
            buttonView.isOn = true;
        }

        const locale = editor.locale;
        const textView = new View(locale);
        window.signals.emit('initFreezeTimeout', 1000);

        textView.setTemplate({
            tag: 'iframe',
            attributes: {
                src: '/iframe-tools/wiris/wiris' + (isOnline() ? 'Online' : 'Offline') + '.html?toolbar=short&language=en&uuid=' + random_id,
                style: {
                    whiteSpace: 'initial',
                    width: '580px',
                    height: '33em'
                },
                tabindex: -1
            },
        });

        dialog.show({
            title: ' ',
            content: textView,
            actionButtons: [
                {
                    label: 'Insert',
                    class: 'ck-button-action',
                    withText: true,
                    onExecute: (widget) => {
                        console.log('DATA ==>', wirisEditorInstance.getMathML());
                        console.log('WIDGET ==>', widget);
                        window.signals.remove('iFrameRegisterObject', setInitialData);
                        // widget.setData('mathml', wirisEditorInstance.getMathML());
                        // widget.setData('iscommit', true);
                        editor.execute('addWiris', wirisEditorInstance.getMathML());
                        dialog.hide();
                    }
                },
                {
                    label: 'Cancel',
                    withText: true,
                    onExecute: () => {
                        dialog.hide();
                        window.signals.emit('initFreezeTimeout');
                    }
                }
            ],
            onHide() { if (buttonView) buttonView.isOn = false; }
        });
    }

    _createFormView() {
        const editor = this.editor;
        const formView = new FormView(editor.locale);

        this.listenTo(formView, 'submit', () => {
            const value = {
                data: formView.dataInputView.fieldView.element.value
            };
            editor.execute('addWiris', value);

            this._hideUI();
        });

        this.listenTo(formView, 'cancel', () => {
            this._hideUI();
        });

        clickOutsideHandler({
            emitter: formView,
            activator: () => this._balloon.visibleView === formView,
            contextElements: [this._balloon.view.element],
            callback: () => this._hideUI()
        });

        return formView;
    }

    _showUI() {
        const selection = this.editor.model.document.selection;

        const commandValue = this.editor.commands.get('addWiris').value;

        this._balloon.add({
            view: this.formView,
            position: this._getBalloonPositionData()
        });

        if (commandValue) {
            this.formView.dataInputView.fieldView.value = commandValue.data;
        } else {
            const selectedText = getRangeText(selection.getFirstRange());
            this.formView.dataInputView.fieldView.value = '';
        }

        this.formView.focus();
    }

    _hideUI() {
        this.formView.dataInputView.fieldView.value = '';
        this.formView.element.reset();
        this._balloon.remove(this.formView);
        this.editor.editing.view.focus();
    }

    _getBalloonPositionData() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;
        let target = null;

        target = () => view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange());

        return {
            target
        };
    }
}
