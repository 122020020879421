import {Command} from '@ckeditor/ckeditor5-core';

export default class SpanCommand extends Command {
    refresh() {
        const {model} = this.editor;
        const {selection} = model.document;

        // this.value = null;
        this.isEnabled = model.schema.checkAttributeInSelection(selection, 'span');
    }

    renderMathML(mathML) {
        // var container = document.getElementById('mathContainer');
        // container.innerHTML = ''; // Container leeren

        // MathJax-Konfiguration
        MathJax.Hub.Config({
            jax: ["input/MathML", "output/SVG"],
            extensions: ["mml2jax.js"],
            SVG: { useGlobalCache: false }
        });

        // Erstellen und Einfügen des MathML-Elements
        var mathElement = document.createElement("div");
        mathElement.style.visibility = "hidden";
        mathElement.style.position = "absolute";
        mathElement.innerHTML = mathML;
        document.body.appendChild(mathElement);

        // MathJax Typisierung und SVG-Ausgabe
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, mathElement, function() {
            var svg = mathElement.getElementsByTagName('svg')[0];
            if (svg) {
                // container.innerHTML = '';
                // container.appendChild(svg);
                console.log('SVG ==>', svg);
            }
            document.body.removeChild(mathElement); // Cleanup
        }]);
    }

    removeBlanks (canvas) {
        var context 	= canvas.getContext("2d"),
            imgWidth 	= canvas.width,
            imgHeight 	= canvas.height;

        var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
            data = imageData.data,
            getRBG = function(x, y) {
                var offset = imgWidth * y + x;
                return {
                    red:     data[offset * 4],
                    green:   data[offset * 4 + 1],
                    blue:    data[offset * 4 + 2],
                    opacity: data[offset * 4 + 3]
                };
            },
            isWhite = function (rgb) {
                // many images contain noise, as the white is not a pure #fff white
                if (rgb.opacity === 0) {
                    return true;
                }
                return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200;
            },
            scanY = function (fromTop) {
                var offset = fromTop ? 1 : -1;

                // loop through each row
                for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                    // loop through each column
                    for(var x = 0; x < imgWidth; x++) {
                        var rgb = getRBG(x, y);
                        if (!isWhite(rgb)) {
                            return y;
                        }
                    }
                }
                return null; // all image is white
            },
            scanX = function (fromLeft) {
                var offset = fromLeft? 1 : -1;

                // loop through each column
                for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                    // loop through each row
                    for(var y = 0; y < imgHeight; y++) {
                        var rgb = getRBG(x, y);
                        if (!isWhite(rgb)) {
                            return x;
                        }
                    }
                }
                return null; // all image is white
            };

        var scanBaseline = function (){


            var yPxCounts = new Array(imgHeight);

            // loop through each column
            for(var y = 0; y < imgHeight; y ++) {

                var curPxCount = 0;

                // loop through each column
                for(var x = 0; x < imgWidth; x++) {
                    var rgb = getRBG(x, y);
                    if (!isWhite(rgb)) {
                        curPxCount++;
                    }
                }

                yPxCounts[y] = curPxCount;
                // LogBridgeService.info(y,"pxCount",curPxCount);
            }
            return null; // all image is white
        };


        var cropTop = scanY(true),
            cropBottom = scanY(false),
            cropLeft = scanX(true),
            cropRight = scanX(false);

        scanBaseline();

        var cropWidth = cropRight - cropLeft+1,
            cropHeight = cropBottom - cropTop+1;

        var $croppedCanvas = $("<canvas>").attr({ width: cropWidth, height: cropHeight });

        // finally crop the guy
        $croppedCanvas[0].getContext("2d").drawImage(canvas,
            cropLeft, cropTop, cropWidth, cropHeight,
            0, 0, cropWidth, cropHeight);

        // LogBridgeService.info(imgWidth,"x",imgHeight,"cropWidth",cropWidth,cropHeight,"cropHeight")
        // LogBridgeService.info("cropTop",cropTop,"cropBottom",cropBottom,"cropLeft",cropLeft,"cropRight",cropRight);

        //return canvas;
        return $croppedCanvas[0];
    };

    fixMathMLDisplayStyle(mathML) {
        if (typeof(mathML) !== 'string' || mathML.length === 0) {
            return mathML;
        }
        var xmlParser = new DOMParser();
        var xmlDoc = xmlParser.parseFromString(mathML,"text/xml");
        xmlDoc.firstChild.setAttribute("displaystyle",true);

        var xmlSerializer = new XMLSerializer();
        return xmlSerializer.serializeToString(xmlDoc);
    };

    /* global MathJax */
    mathml2svg(that, editor, mathmlString, draggedElementUUID, LogBridgeService) {

        const {model} = editor;
        const {selection} = model.document;
        let editorModel = editor.model;
        let editorDocument = editorModel.document;

        var desmosText = angular.element(document).injector().get('$rootScope').copyTexString;
        var data_copy = mathmlString;

        // MathJax Konfiguration für MathML zu SVG
        window.MathJax = {
            jax: ["input/MathML", "output/SVG"],
            'HTML-CSS': {
                scale: 100,
                availableFonts: ['Neo-Euler'],
                webFont: 'Neo-Euler',
                preferredFont: 'Neo-Euler'//TeX, STIX-Web, Asana-Math, Neo-Euler, Gyre-Pagella, Gyre-Termes and Latin-Modern
            },
            extensions: ["mml2jax.js", "MathMenu.js", "MathZoom.js"],
            showMathMenu: false,
            showProcessingMessages: false,
            messageStyle: "none",
            SVG: {
                useGlobalCache: false
            },
            AuthorInit: function () {
                try {
                    console.log("Rendering MathML expression: " + mathmlString);
                    mathmlString = that.fixMathMLDisplayStyle(mathmlString);


                    let uuid = draggedElementUUID || angular.createUUID();
                    var html = '<img id="' + uuid + '" class="wiris-image-singleline preload" alt="' + uuid + '" src="../images/desmosLoader.gif" style="border:1px solid #ececec; padding: 2px; background-color: #fff">';

                    const newElement = editor.data.processor.toView(html);
                    newElement.getChild(0)._setAttribute('data-mathml', window.btoa(mathmlString));
                    const modelFragment = editor.data.toModel(newElement);
                    modelFragment.className = 'wiris-widget';

                    model.change(writer => {
                        const {end: positionAfter} = model.insertContent(
                            writer.createElement('imageInline', {
                                src: '../images/desmosLoader.gif',
                                dSource: window.btoa(mathmlString),
                                alt: uuid,
                                class: 'wiris-image-singleline preload',
                                uuid: uuid
                            })
                        );

                        writer.setSelection(positionAfter);
                        writer.removeSelectionAttribute('span');
                    });

                    editor.execute('enter');
                    MathJax.Hub.Register.StartupHook("End", function () {
                        var wrapper = document.createElement("div");
                        wrapper.innerHTML = mathmlString;
                        var output = {svg: "", img: ""};
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub, wrapper]);
                        MathJax.Hub.Queue(function () {
                            console.log("Rendering MathJax output to PNG", mathmlString);

                            var mjOut = wrapper.getElementsByTagName("svg")[0];
                            mjOut.setAttribute("xmlns", "http://www.w3.org/2000/svg");

                            var exWidth = parseFloat(mjOut.getAttribute('width'));
                            var exHeight = parseFloat(mjOut.getAttribute('height'));
                            var exToPxFactor = 8;
                            var pxWidth = exWidth * exToPxFactor;
                            var pxHeight = exHeight * exToPxFactor;

                            mjOut.setAttribute("width", (pxWidth * 2) + 'px');
                            mjOut.setAttribute("height", (pxHeight * 2) + 'px');
                            console.log('MJ DATA ==>', mjOut.outerHTML);


                            var canvas = document.createElement('canvas');
                            LogBridgeService.info('[CK WIRIS] now removing blanks');
                            var croppedCanvas = that.removeBlanks(canvas);

                            var opts = {
                                renderCallback: function () {
                                    var currentElementLoading = document.querySelector('span[data-source="' + window.btoa(mathmlString) + '"]');

                                    if (currentElementLoading) {
                                        const findElementRecursively = (node, targetDataSource) => {
                                            if (node._attrs && node._attrs.get('dSource') === targetDataSource &&  node._attrs.get('uuid') === uuid) {
                                                return node;
                                            }
                                            if (node._children && Array.isArray(node._children._nodes)) {
                                                for (let child of node._children._nodes) {
                                                    let found = findElementRecursively(child, targetDataSource, uuid);
                                                    if (found) return found;
                                                }
                                            }
                                            return null;
                                        };

                                        const imgClass =  'wiris-image-singleline';
                                        LogBridgeService.info('[CK WIRIS] Cropped image '+croppedCanvas.width+'x'+croppedCanvas.height+' - imgClass '+imgClass);
                                        model.change(writer => {
                                            let node = findElementRecursively(editorDocument.getRoot(), window.btoa(mathmlString));
                                            if (node) {
                                                writer.setAttributes({
                                                    src: 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(mjOut.outerHTML),
                                                    width: pxWidth,
                                                    height: pxHeight,
                                                    class: imgClass,
                                                    uuid: uuid
                                                }, node);
                                            }
                                        });
                                    } else {
                                        that.mathml2svg(editor, mathmlString, draggedElementUUID);
                                    }
                                    mjOut.remove();
                                }
                            };
                            canvg(croppedCanvas, mjOut.outerHTML, opts);
                        });
                    });
                } catch (e) {
                    console.error('Error Rendering MathML expression: ' + mathmlString + ' -->' + e, e);
                }
            }
        };

        // Laden von MathJax
        $.getScript('/scripts/vendors/ckeditor5/custom-plugins/wiris/mathjax-mml.js')
            .done(function (script, textStatus) {
                console.log('MathJax loaded successfully.');
            })
            .fail(function (script, settings, exception, textStatus) {
                console.log('Error loading MathJax:', script.statusText);
            });
    };

    execute(data) {
        const {model} = this.editor;
        let uuid = angular.createUUID();

        // this.getDesmosText(this.editor, 'test');
        // if (data) {
        //     this.getDesmosText(this.editor, data);
        // } else {
        //     this.getDesmosText(this.editor);
        // }
        let angularInjector = angular.element('*[data-ng-app]').injector();
        let LogBridgeService = angularInjector.get('LogBridgeService');
        this.mathml2svg(this, this.editor, data, uuid, LogBridgeService);
    }
}
